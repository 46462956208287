import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import AppInfo from "../../components/app-info"
import Icon from "../../components/images/icons/image_icon_calc"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <AppInfo
      Icon={Icon}
      appName="電卓Plus"
      category="仕事効率化"
      price="無料"
      release="2015/10/14"
      language="日本語・英語"
      lastUpdate="2015/10/14"
      version="1.0"
    />
  </Layout>
)

export default IndexPage

import React from "react"

const AppInfo = ({
  Icon,
  isRadius,
  appName,
  category,
  price,
  release,
  language,
  lastUpdate,
  version,
}) => (
  <section className="py-3 d-flex flex-column flex-md-row">
    <div>
      <Icon className={isRadius ? "app-icon radius" : "app-icon"} />
    </div>
    <div className="container pt-3 pt-md-0 pl-0 pl-md-3">
      <h1>{appName}</h1>
      <dl className="row pl-2">
        <dt className="col-sm-5">カテゴリ</dt>
        <dd className="col-sm-7">{category}</dd>
        <dt className="col-sm-5">価格</dt>
        <dd className="col-sm-7">{price}</dd>
        <dt className="col-sm-5">リリース</dt>
        <dd className="col-sm-7">{release}</dd>
        <dt className="col-sm-5">言語</dt>
        <dd className="col-sm-7">{language}</dd>
        <dt className="col-sm-5">最終更新</dt>
        <dd className="col-sm-7">{lastUpdate}</dd>
        <dt className="col-sm-5">現在のバージョン</dt>
        <dd className="col-sm-7">{version}</dd>
      </dl>
    </div>
  </section>
)

export default AppInfo
